import React from "react";
import classnames from "classnames";

interface ContentTileProps {
  type: "text" | "image";
  position: "right" | "left";
  headline: string;
  copy: string;
  imageUrl: string;
  imageAlt: string;
  ctaCopy: string;
  ctaTarget: string;
}

export function ContentTile({
  type,
  position,
  headline,
  copy,
  imageUrl,
  imageAlt,
  ctaCopy,
  ctaTarget,
}: ContentTileProps) {
  const styles = classnames({
    ["contentTile-text"]: type === "text",
    ["contentTile-img"]: type === "image",
    ["contentTile--left"]: position === "left",
    ["contentTile--right"]: position === "right",
  });

  return (
    <div className={styles}>
      {headline && <h2>{headline}</h2>}
      {copy && <p>{copy}</p>}
      {imageUrl && (
        <div>
          <img src={imageUrl} alt={imageAlt} />
        </div>
      )}
      {ctaCopy && ctaTarget && (
        <a className="cta-button" href={ctaTarget}>
          {ctaCopy}
        </a>
      )}
    </div>
  );
}
