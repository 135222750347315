import React from "react";

export function Footer() {
  const year = new Date(Date.now()).toDateString().split(" ")[3];

  return (
    <footer>
      {year} |{" "}
      <a href="mailto:info@benwebbfurniture.com?subject=Inquiry">
        info@benwebbfurniture.com
      </a>{" "}
      | Amsterdam | KVK 88519635 |{" "}
      <a href="https://www.instagram.com/benwebbfurniture/" target="_blank">
        Instagram
      </a>
    </footer>
  );
}
