import "./App.css";

import { Content } from "./cms/Content.ts";

import { ContentTile } from "./ContentTile/ContentTile.tsx";
import { GallerySection } from "./GallerySection/GallerySection.tsx";
import { ProcessSection } from "./ProcessSection/ProcessSection.tsx";
import { FormSection } from "./FormSection/FormSection.tsx";
import { Spacer } from "./Spacer/Spacer.tsx";
import { Footer } from "./Footer/Footer.tsx";
import { NavBar } from "./NavBar/NavBar.tsx";

function App() {
  return (
    <div className="page-container">
      <div className="layout-container">
        <NavBar />
        <section className="section" id="about">
          <ContentTile
            type="text"
            position="left"
            headline={Content.about.headline}
            ctaCopy={Content.about.ctaCopy}
            ctaTarget={Content.about.ctaTarget}
            copy={Content.about.copy}
          />
          <ContentTile
            type="image"
            position="right"
            imageUrl={Content.about.image}
            imageAlt={Content.about.imageAlt}
          />
        </section>
        <section className="section" id="work">
          <ContentTile type="image" position="left" imageUrl="" imageAlt="" />
          <ContentTile
            type="text"
            position="right"
            headline={Content.gallery.headline}
            copy={Content.gallery.copy}
          />
        </section>
        <section className="section">
          <GallerySection images={Content.gallery.images} />
        </section>
        <section className="section">
          <ContentTile
            type="text"
            position="left"
            headline={Content.process.ctaHeader}
            copy={Content.process.ctaCopy}
          />
          <ContentTile type="image" position="right" imageUrl="" imageAlt="" />
        </section>
        <section className="section" id="process">
          <ContentTile
            type="image"
            position="left"
            imageUrl={Content.process.image}
            imageAlt={Content.process.imageAlt}
          />
          <ContentTile
            type="text"
            position="right"
            headline={Content.process.headline}
            copy={Content.process.copy}
          />
        </section>
        <Spacer />
        <section className="section">
          <ProcessSection table={Content.process.table} />
        </section>
        <Spacer />
        <section className="section" id="contact">
          <ContentTile
            type="text"
            position="left"
            headline={Content.contact.headline}
            copy={Content.contact.copy}
          />
          <FormSection
            fields={Content.contact.form}
            thankYouMessage={Content.contact.thankYouMessage}
          />
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default App;
