export const Content = {
    about: {
        headline: "Hi, I’m Ben - A Furniture maker in Amsterdam",
        copy: "I design and build contemporary furniture for peoples homes which can be loved and enjoyed for generations. If you are interested in having a bespoke piece of furniture made, please take a look at some of my most recent pieces below and feel free to get in touch so we can discuss what you are looking for.",
        ctaCopy: "Get In Touch",
        ctaTarget: "#contact",
        image: "images/portrait.webp",
        imageAlt: "Bespoke Furniture Maker in Amsterdam"
    },
    gallery: {
        headline: "Selected Projects",
        copy: "Below are some examples of the most recent pieces I've built.",
        images: [
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/53284ab2af/tv_cabinet_1.jpg",
                alt: "TV Unit",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/a41ba4945f/tv_cabinet_2.jpg",
                alt: "TV Unit",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/79c6969614/tv_cabinet_4.jpg",
                alt: "TV Unit",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/8e489002c5/tv_cabinet_3.jpg",
                alt: "TV Unit",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/b77f2ab501/sideboard_2.jpg",
                alt: "Side cabinet",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/31dddee1ba/sideboard_1.jpg",
                alt: "Side cabinet",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/d0c364d5a9/sideboard_4.jpg",
                alt: "Side cabinet",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/ecbec9be91/sideboard_3.jpg",
                alt: "Side cabinet",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/791e03fb66/picture_shelf_1.jpg",
                alt: "Picture shelf",
                width: 4,
                height: 3,
            },
            {
                src: "https://a.storyblok.com/f/85904/4000x2667/c4c3e47af1/picture_shelf_2.jpg",
                alt: "Picture shelf",
                width: 4,
                height: 3,
            },
            {
                src: "images/table-2.webp",
                alt: "American Walnut Side Table",
                width: 3,
                height: 4,
            },
            {
                src: "images/table-1.webp",
                alt: "American Walnut Side Table Drawer with Parquetry",
                width: 4,
                height: 3,
            },
            {
                src: "images/box-1.webp",
                alt: "Custom Polaroid Keep Safe Box",
                width: 4,
                height: 3,
            },
            {
                src: "images/box-2.webp",
                alt: "Custom Polaroid Keep Safe Box Internals",
                width: 4,
                height: 3,
            },
            {
                src: "images/remote-box.jpg",
                alt: "TV Remote Box",
                width: 4,
                height: 3,
            },
        ]
    },
    process: {
        ctaHeader: "Like what you see?",
        ctaCopy: "Learn more about how we can build your bespoke piece of furniture together.",
        headline: "My Process",
        copy: "Having a bespoke piece of furniture made is an exciting adventure. Below is an overview of the steps we can take together to create a beautiful piece of furniture, so you know what to expect from the process.",
        image: "images/work.webp",
        imageAlt: "Furniture Maker in Amsterdam using Vertias Jack Plane",
        table: {
            explore: "We’ll start off with an initial discussion about what it is you’re looking for. This can be as simple as a rough description as to the type of furniture, where it’s going to live, what type of style you have in mind, any particular wood preference etc. This will help set the stage.",
            design: "If after this, it seems like a match, then we’ll move into some design concepts. This can take the form of some rough design sketches to see what takes your fancy, or, depending on the piece, maybe even jumping straight into some 3D models. We’ll then have a follow up chat to discuss what elements of the design concepts you like and areas which maybe need more work. We'll then land on a final design concept.",
            make: "After that, it’s over to the really fun part. The making! Through out the process, we’ll be in regular contact to give you progress updates and, if there are any final design decisions which need to be made when the piece starts to come to life."
        }
    },
    contact: {
        headline: "Get in touch",
        copy: "If you are interested in commissioning a bespoke piece of furniture, then please get in touch using the form and letting me know a little bit more about what you are looking for. I'll aim to respond within 1 business day.",
        form: {
            name: {
                label: "Name",
                context: "Hello, nice meeting you.",
                placeholder: "Your name"
            },
            contact: {
                label: "Contact (email or phone)",
                context: "We will only use your details to get in touch with you regarding your enquiry",
                placeholder: "name@name.com"
            },
            description: {
                label: "Description",
                context: "Let me know what you are roughly looking for. If you have any reference links, feel free to include them.",
                placeholder: "I am looking for..."
            },
            timeline: {
                label: "Timeline",
                context: "If you have any specific requirements on your timeline, let us know here",
                placeholder: "day/month"
            },
            budget: {
                label: "Budget",
                context: "How much do you roughly want to spend?",
            }
        },
        thankYouMessage: "Thank you very much for submitting your request. I'll aim to respond to your request within 1 business day."
    }
}
