import React, { useState } from "react";

export function FormSection({ fields, thankYouMessage }) {
  const [name, setName] = useState(fields.name.placeholder);
  const [contact, setContact] = useState(fields.contact.placeholder);
  const [description, setDescription] = useState(
    fields.description.placeholder
  );
  const [timeline, setTimeline] = useState(fields.timeline.placeholder);
  const [budget, setBudget] = useState(750);

  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleResetName() {
    if (name === fields.name.placeholder) {
      setName("");
    }
  }

  function handleResetContact() {
    if (contact === fields.contact.placeholder) {
      setContact("");
    }
  }

  function handleResetDescription() {
    if (description === fields.description.placeholder) {
      setDescription("");
    }
  }

  function handleResetTimeline() {
    if (timeline === fields.timeline.placeholder) {
      setTimeline("");
    }
  }

  function handleOnSubmit(e) {
    e.preventDefault();

    let url =
      "https://api.sheety.co/5170e23598c9a17c11d3f0577571efe2/benWebbFurnitureRequests/requests";

    let body = {
      request: {
        name,
        contact,
        description,
        timeline,
        budget,
      },
    };

    if (!name || !contact || !description || !timeline || !budget) {
      return;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.request) {
          setIsSubmitted(true);
        }
      });
  }

  return isSubmitted ? (
    <form>
      <p>{thankYouMessage}</p>
    </form>
  ) : (
    <form>
      <label htmlFor="name">{fields.name.label}</label>
      <p className="formSection-context">{fields.name.context}</p>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onFocus={handleResetName}
      />
      <label htmlFor="contact">{fields.contact.label}</label>
      <p className="formSection-context">{fields.contact.context}</p>
      <input
        type="text"
        id="contact"
        value={contact}
        onChange={(e) => setContact(e.target.value)}
        onFocus={handleResetContact}
      />
      <label htmlFor="description">{fields.description.label}</label>
      <p className="formSection-context">{fields.description.context}</p>
      <textarea
        id="description"
        value={description}
        rows={10}
        onChange={(e) => setDescription(e.target.value)}
        onFocus={handleResetDescription}
      />
      <label htmlFor="timeline">{fields.timeline.label}</label>
      <p className="formSection-context">{fields.timeline.context}</p>
      <input
        type="text"
        id="timeline"
        value={timeline}
        onChange={(e) => setTimeline(e.target.value)}
        onFocus={handleResetTimeline}
      />
      <label htmlFor="budget">{fields.budget.label}</label>
      <p className="formSection-context">{fields.budget.context}</p>
      <span className="formSection-range">
        <input
          type="range"
          id="budget"
          value={budget}
          min="0"
          max="10000"
          onChange={(e) => setBudget(Number(e.target.value))}
        />
        <span>{budget} €</span>
      </span>
      <span>
        <button
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          Submit
        </button>
      </span>
    </form>
  );
}
