import React from "react";

export function ProcessSection({ table }) {
  return (
    <div className="processSection-wrapper">
      <div className="processSection-col">
        <h2>Explore</h2>
        <p>{table.explore}</p>
      </div>
      <div className="processSection-col">
        <h2>Design</h2>
        <p>{table.design}</p>
      </div>
      <div className="processSection-col">
        <h2>Make</h2>
        <p>{table.make}</p>
      </div>
    </div>
  );
}
