import React from "react";

export function NavBar() {
  return (
    <nav className="nav-bar-container">
      <div className="logo">
        {/* <Logo /> */}
        <img src="logo-font.svg" alt="Logo" />
      </div>
      <div className="nav-bar-items-wrapper">
        <a href="#about"> About </a>
        <a href="#work"> Work </a>
        <a href="#process"> Process </a>
        <a href="#contact"> Contact </a>
      </div>
    </nav>
  );
}
